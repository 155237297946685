<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 170px; width:411px">
        <field
          name="codigo"
          widget="char"
          searchable
          label="Código"
          help="Código"
          placeholder="Cod"
          width="50px"
          :readonly="mode == 'edit'"
          style="top:9px; left:30px;"
        />
        <field
          name="cliente_id"
          widget="m2o"
          dbAdapter="cliente"
          label="Cliente"
          labelProp="nombre_comercial"
          searchable
          width="270px"
          style="top:9px; left:95px;"
          required
        />
        <field
          name="plantilla_id"
          widget="m2o"
          dbAdapter="plantilla_informe"
          :filter="[['model', '=', 'cliente']]"
          label="Plantilla"
          searchable
          width="270px"
          style="top:50px; left:95px;"
          required
        />
        <field
          name="documentos"
          :max="6"
          widget="files"
          label="Adjuntos"
          style="top:90px; left:95px;"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Documentos de clientes",
      dbAdapter: "documento_cliente",
      primary: "codigo"
    };
  }
};
</script>
